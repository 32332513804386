import { Collapse, Skeleton, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { m } from 'framer-motion';
// @mui
import { alpha, SxProps, Theme } from '@mui/material/styles';
import { Box } from '@mui/system';
import {
  // editor
  locale_en_gb,
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,

  // plugins
  setPlugins,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_finetune,
  plugin_finetune_locale_en_gb,
  plugin_finetune_defaults,
  plugin_filter,
  plugin_filter_locale_en_gb,
  plugin_filter_defaults,
  plugin_annotate,
  plugin_annotate_locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb,
} from '@pqina/pintura';
import { PinturaEditorModal } from '@pqina/react-pintura'; // utils import { fData } from 'src/utils/format-number';
import { useQueryState } from 'nuqs';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { cloudStorageService } from 'src/api/cloud-storage';

import { varFade } from '../animate';

import '@pqina/pintura/pintura.css';

import FileThumbnail, { fileData } from '../file-thumbnail';
import Iconify from '../iconify';
import { CustomFile } from './types';

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate);

const editorDefaults = {
  theme: 'dark',
  imageReader: createDefaultImageReader(),
  imageWriter: createDefaultImageWriter(),
  shapePreprocessor: createDefaultShapePreprocessor(),
  ...plugin_finetune_defaults,
  ...plugin_filter_defaults,
  ...markup_editor_defaults,
  locale: {
    ...locale_en_gb,
    ...plugin_crop_locale_en_gb,
    ...plugin_finetune_locale_en_gb,
    ...plugin_filter_locale_en_gb,
    ...plugin_annotate_locale_en_gb,
    ...markup_editor_locale_en_gb,
  },
};

type PreviewProps = {
  index: number;
  file: File;
  files: (string | File)[];
  onRemove: ((file: CustomFile | string) => void) | undefined;
  sx?: SxProps<Theme> | undefined;
};
export default function Preview({ file, index, files, onRemove, sx }: PreviewProps) {
  const methods = useFormContext();

  const { getValues, setValue } = methods;

  const [visible, setVisible] = useQueryState('editor');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const handleSaveFile = async (processedFile: Blob) => {
    setLoading(true);
    const newFile = new File([processedFile], 'edited-image.jpg', { type: 'image/jpeg' });
    // Here you can upload, save to state, or handle the file as needed

    const response = await cloudStorageService.upload(newFile?.name, 'users', newFile);
    const updatedFiles = files.filter((item, idx) => idx !== index);

    if (response) {
      setValue('images', [...updatedFiles, response], { shouldValidate: true });
    } else {
      setError(
        'Image no good' // This is the error message
      );
      setTimeout(() => {
        setError(undefined); // Clear the error message after 2 seconds
      }, 5000);
    }
    setVisible(null);
    setLoading(false);
  };

  const handleOpenEdit = () => {
    setVisible(index.toString());
    setError(undefined);
  };

  return (
    <Stack
      component={m.div}
      {...varFade().inUp}
      alignItems="center"
      display="inline-flex"
      justifyContent="center"
      sx={{
        // m: 0.5,
        width: '100%',
        height: '20rem',
        borderRadius: 1.25,
        overflow: 'hidden',
        position: 'relative',
        border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
        ...sx,
      }}
    >
      {loading && <Skeleton sx={{ width: '100%', height: '100%' }} />}
      {!loading && (
        <FileThumbnail
          tooltip
          imageView
          file={file}
          sx={{ position: 'absolute' }}
          imgSx={{ position: 'absolute' }}
        />
      )}

      {onRemove && (
        <IconButton
          size="small"
          onClick={() => onRemove(file)}
          sx={{
            p: 0.5,
            top: 4,
            right: 4,
            position: 'absolute',
            color: 'common.white',
            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }}
        >
          <Iconify icon="mingcute:close-line" width={14} />
        </IconButton>
      )}
      <IconButton
        size="small"
        onClick={() => handleOpenEdit()}
        sx={{
          p: 0.5,
          top: 4,
          left: 4,
          position: 'absolute',
          color: '#000',
          bgcolor: '#FFD369',
        }}
      >
        <Iconify icon="line-md:pencil" width={14} />
      </IconButton>
      {visible === index.toString() && (
        <PinturaEditorModal
          {...editorDefaults}
          utils={['crop', 'finetune', 'filter', 'annotate']}
          src={fileData(file).preview}
          onLoad={(res: any) => {}}
          onHide={() => setVisible(null)}
          onProcess={({ dest }: any) => {
            handleSaveFile(dest);
          }}
        />
      )}
      <Collapse
        in={!!error}
        timeout={500}
        sx={{
          position: 'absolute',
          bottom: 0,
          backgroundcolor: '#000',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <Box sx={{ p: 1 }}>
          <Typography>{error}</Typography>
        </Box>
      </Collapse>
    </Stack>
  );
}
